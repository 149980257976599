import { defineStore } from 'pinia';
import { gql } from '@apollo/client/core';
import { type Banner, type BannerEdge } from '#root/shared/types/graphql-types';
import { productFragment } from '@/graphql/fragments';
import { categorizedBanners } from '~/config/banners-config';

export interface BannerStoreState {
  page: string;
  banners: Banner[];
  simpleBanners: Banner[];
  simpleBannersLoading: boolean;
  loading: boolean;
}

export enum BannerAnimalType {
  DOG = 'dog',
  CAT = 'cat',
  SMALL_PETS = 'smallPets',
  HORSE = 'horse',
}

type CategorizedBanners = {
  [animalType in BannerAnimalType]: Banner[];
};

export const useBannerStore = defineStore('banners', {
  state: (): BannerStoreState => ({
    page: '',
    banners: [],
    loading: false,
    simpleBanners: [], // banners without products, used on startpage and black week page
    simpleBannersLoading: false,
  }),
  getters: {
    getCategorizedBanners: ({ simpleBanners }) => {
      const nuxtApp = useNuxtApp();
      const locale = nuxtApp.$i18n.locale.value as LocaleTerritory;
      const localeBannerConfig =
        categorizedBanners[locale as keyof typeof categorizedBanners];

      const { isProd } = useRuntimeConfig().public;

      const bannerIds = isProd
        ? localeBannerConfig?.prod
        : localeBannerConfig?.dev;

      if (!bannerIds) {
        console.warn('No config for categorized banners found for locale');
        return {};
      }

      return simpleBanners?.reduce(
        (acc: CategorizedBanners, banner) => {
          Object.keys(bannerIds).forEach((animalType) => {
            if (
              !banner.hide &&
              bannerIds[animalType as BannerAnimalType].includes(
                banner.entityId
              )
            ) {
              acc[animalType as BannerAnimalType].push(banner);
            }
          });
          return acc;
        },
        { dog: [], cat: [], smallPets: [], horse: [] }
      );
    },
    getBannersFilteredByName:
      ({ simpleBanners }) =>
      (bannerName: string) =>
        simpleBanners.filter((banner) =>
          banner.name.toLowerCase().includes(bannerName)
        ),
    getNonHiddenBanners: ({ banners }) =>
      banners.filter((banner) => !banner.hide),
    getNonHiddenSimpleBanners: ({ simpleBanners }) =>
      simpleBanners.filter((simpleBanner) => !simpleBanner.hide),
  },
  actions: {
    async getBanners(page: string) {
      this.loading = true;
      const bannerQuery = gql`
        query Banners($first: Int, $after: String) {
          banners {
            edges {
              node {
                entityId
                campaignFilter {
                  attributeName
                  attributeValue
                  searchFilters
                }
                path
                name
                displayName
                slug
                imageUrl
                location
                hide
                showOnStartpage
                couponCode
                products(first: $first, after: $after) {
                  edges {
                    node {
                      brand {
                        entityId
                        name
                        path
                        slug
                      }
                      categoryIds
                      defaultVariant {
                        entityId
                      }
                      listBadge
                      ...ProductFields
                    }
                  }
                  pageInfo {
                    totalPages
                    startCursor
                  }
                }
              }
            }
          }
        }
        ${productFragment}
      `;

      const { data: bannerResults } = await useAsyncQuery({
        query: bannerQuery,
        variables: {
          first: 36,
          after: page,
        },
      });
      this.loading = false;
      this.page = page;
      this.banners = bannerResults?.value?.data?.banners?.edges
        ?.map((banner: any) => banner.node)
        ?.filter((banner: any) => banner.campaignFilter || banner.products);
    },

    async getSimpleBanners() {
      this.simpleBannersLoading = true;

      const result = await useAsyncQuery({
        query: gql`
          query Banners {
            banners {
              edges {
                node {
                  entityId
                  campaignFilter {
                    attributeName
                    attributeValue
                    searchFilters
                  }
                  hide
                  path
                  name
                  displayName
                  slug
                  imageUrl
                  location
                  showOnStartpage
                  couponCode
                }
              }
            }
          }
        `,
      });

      this.simpleBanners =
        result.data?.value?.data?.banners?.edges.map(
          (banner: BannerEdge) => banner.node
        ) || [];

      this.simpleBannersLoading = false;
      return result;
    },
  },
});
