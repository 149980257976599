const categorizedBanners = {
  sv: {
    dev: {
      dog: [23, 22, 19, 18, 13, 6, 2, 21, 10],
      cat: [19, 18, 13],
      smallPets: [6, 2],
      horse: [21, 10],
    },
    prod: {
      dog: [],
      cat: [],
      smallPets: [],
      horse: [],
    },
  },
};

export { categorizedBanners };
